<template>
  <div>
    <bread-crumb :crumbData="[
      '营销活动',
      '拼团活动',
      this.$store.state.chooseModule.row ? '编辑' : '新增',
    ]" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <div style="padding: 50px; padding-right: 10%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="活动标题" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="活动规则" prop="rule">
          <el-input v-model="ruleForm.rule"></el-input>
        </el-form-item>
        <el-form-item label="缩例图" prop="pic">
          <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="httpRequest">
            <img v-if="ruleForm.pic" :src="'http://192.168.3.3' + ruleForm.pic" class="avatar" />
            <el-button size="small" type="primary" class="uploadBtu">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <div style="display: flex">
          <el-form-item label="单买价格" prop="singlePrice">
            <el-input v-model="ruleForm.singlePrice"></el-input>
          </el-form-item>
          <el-form-item label="团购价格" prop="teamPrice">
            <el-input v-model="ruleForm.teamPrice"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="活动库存" prop="StockNum">
            <el-input v-model="ruleForm.StockNum"></el-input>
          </el-form-item>
          <el-form-item label="目标人数" prop="peopleNum">
            <el-input v-model="ruleForm.peopleNum"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="每人限购" prop="limitation">
          <el-input v-model="ruleForm.limitation"></el-input>
        </el-form-item>
        <el-form-item label="商品清单" prop="inventory">
          <el-table :data="ruleForm.inventory" border style="width: 100%" empty-text=" ">
            <el-table-column label="图片" align="center">
              <template slot-scope="scope">
                <img :src="'http://192.168.3.3' + scope.row.thumbnail" class="product-img" />
              </template>
            </el-table-column>
            <el-table-column prop="nike_name" align="center" label="品名">
            </el-table-column>
            <el-table-column prop="specifications" align="center" label="规格">
            </el-table-column>
            <el-table-column prop="number" align="center" label="型号">
            </el-table-column>
            <el-table-column prop="sales_price" align="center" label="价格">
            </el-table-column>
            <el-table-column prop="now_inventory" align="center" label="数量">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <i class="el-icon-delete poiner" @click="handleDelete(scope.$index, scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
          <el-button type="primary" @click="productAdd">添加商品</el-button>
        </el-form-item>
        <el-form-item label="赠劵" prop="region">
          <el-select v-model="ruleForm.region" placeholder="--选择--">
            <el-option label="区域二" value="北京"></el-option>
            <el-option label="区域三" value="上海"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="ruleForm.status">
            <el-radio label="待发布"></el-radio>
            <el-radio label="进行中"></el-radio>
            <el-radio label="已关闭"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加商品弹窗 -->
    <el-dialog title="商品选择" :visible.sync="dialogVisible" :before-close="handleClose" width="64%">
      <div class="dialogSelect">
        <el-select v-model="value1" style="width: 14% !important" clearable>
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value2" style="width: 14% !important" clearable>
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input style="width: 25%; margin: 0 5px" v-model="searchVal" placeholder="请输入你要查询的内容"></el-input>
        <el-button type="success" icon="el-icon-search" @click="handleInquire">查询</el-button>
        <el-button type="primary" @click="addConfirm">确定</el-button>
      </div>
      <div style="padding: 20px; margin-top: 10px">
        <div class="file_form">
          <!-- 文件夹处 -->
          <!-- <div class="file">
            <div class="file_level1" @click="isFileShow = !isFileShow">
              <img v-if="!isFileShow" style="width: 10px; height: 10px" :src="require('@/assets/mianAssets/文件夹加.png')"
                alt="" />
              <img v-if="isFileShow" style="width: 10px; height: 10px" :src="require('@/assets/mianAssets/文件夹减.png')"
                alt="" />
              <img style="width: 22px; height: 22px; margin-left: 5px" :src="require('@/assets/mianAssets/文件夹.png')"
                alt="" />
              <a href="#">全部文件</a>
            </div>
            <div class="file_level2" v-if="isFileShow">
              <ul>
                <li v-for="(file, index) in filStorages" :key="file" style="display: flex; align-items: center"
                  @click="fileClick(index)">
                  <img style="width: 12px; margin-right: 3px" :src="require('@/assets/mianAssets/空白页.png')" alt="" /><a
                    href="#" :title="file">
                    <span :class="isLabel == index ? 'label' : ''">{{
                      file
                    }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div> -->
          <el-col :span="4">
            <!-- 文件夹处 -->
            <List :classList="classList" @allFile="allFile" @labelClick="labelClick" />
          </el-col>
          <!-- 表格 -->
          <div style="padding: 10px 0; width: 100%">
            <el-table ref="multipleTable" :border="true" :data="tableData1" tooltip-effect="dark"
              style="width: 100%; border: 1px solid #e5e5e5" @selection-change="handleSelectionChange"
              @select="tabelChenge" @select-all="tabelAllChenge">
              <el-table-column type="selection" align="center">
              </el-table-column>
              <el-table-column prop="tid" sortable label="编号" align="center">
              </el-table-column>
              <el-table-column prop="product_name" label="名称" align="center">
              </el-table-column>
              <el-table-column prop="unit" label="单位" align="center">
              </el-table-column>
              <el-table-column prop="market_price" sortable label="市场价" align="center">
              </el-table-column>
              <el-table-column prop="sales_price" sortable label="销售价" align="center">
              </el-table-column>
            </el-table>
            <div class="tableBottom">
              <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
                tableFinshNum
              }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
              <span v-if="!isResult" style="">显示&nbsp;{{ tableData1.length }}&nbsp;项结果，共&nbsp;{{
                tableFinshNum
              }}&nbsp;项结果</span>
              <div style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                ">
                <el-button>首页</el-button>
                <el-pagination background layout="prev,pager,next" :total="tableFinshNum" :page-size="6"
                  @current-change="currentChange" @prev-click="backPage" @next-click="nextPage"></el-pagination>
                <el-button>末页</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import qs from "qs";
import List from "@/components/main/list";
export default {
  props: ["label1", "label2"],
  components: { List },
  data() {
    return {
      commercial_id: localStorage.getItem("commercial_id") ||
        sessionStorage.getItem("commercial_id"),
      ruleForm: {
        name: "",
        rule: "",
        pic: "",
        singlePrice: "",
        teamPrice: "",
        StockNum: "",
        peopleNum: "",
        limitation: 0,
        inventory: [],
        region: "",
        status: "",
        addList: [],
        cid: 0,
      },
      rules: {
        name: [{ required: true, message: " " }],
        rule: [{ required: true, message: " " }],
        pic: [{ required: true, message: " " }],
        singlePrice: [{ required: true, message: " " }],
        teamPrice: [{ required: true, message: " " }],
        StockNum: [{ required: true, message: " " }],
        peopleNum: [{ required: true, message: " " }],
        limitation: [{ required: true, message: " " }],
        inventory: [{ required: true, message: " " }],
        status: [{ required: true, message: " " }],
      },
      fileList: [],
      imageUrl: "",
      tableData: [],
      dialogVisible: false,
      options1: [
        {
          "label": "推荐",
          "value": 0
        },
        {
          "label": "新品",
          "value": 1
        },
        {
          "label": "精选",
          "value": 2
        }
      ],
      options2: [
        {
          "label": "普通商品",
          "value": 0
        },
        {
          "label": "同城商品",
          "value": 1
        },
        {
          "label": "虚拟商品",
          "value": 2
        },
        {
          "label": "赠品",
          "value": 3
        }
      ],
      value1: "",
      value2: "",
      searchVal: "",
      isFileShow: false,
      isLabel: -1,
      filStorages: ["到店自提", "自提水票", "配送专区"],
      tableData1: [],
      isResult: false,
      tableFinshNum: 0,
      page: 1,
      dialogImageUrl: "",
      disabled: false,
      id: 0,
      length: 6,
      commercialId: 0,
      classList: [],
    };
  },

  computed: {},
  mounted() {
    this.commercialId =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    if (this.$store.state.chooseModule.row) {
      const row = this.$store.state.chooseModule.row;
      this.ruleForm.name = row.activity_title;
      this.ruleForm.rule = row.activity_rule;
      this.ruleForm.pic = row.thumbnail;
      this.ruleForm.singlePrice = row.purchase_price;
      this.ruleForm.teamPrice = row.group_price;
      this.ruleForm.inventory = row.product_arr;
      this.ruleForm.peopleNum = row.target_number;
      this.ruleForm.limitation = row.limited_purchase;
      this.ruleForm.region = row.coupon;
      this.ruleForm.status = row.status;
      this.ruleForm.StockNum = row.activity_inventory;
      this.id = row.id;
    }
  },
  created() {
    this.getClassList();
  },
  methods: {
    allFile() {
      // this.isFileShow = !this.isFileShow;
      if (this.cid == 0) return;
      this.cid = 0;
      this.inquireList();
    },
    inquireList() {
      this.$http
        .post("/product/lst", {
          currentPage: this.currentPage,
          currentLength: this.currentLength,
          cid: this.cid,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData1 = res.data.data;
          this.dialogVisible = true;
          this.tableFinshNum = res.data.count;
        });
    },
    labelClick(id) {
      this.cid = id;
      this.inquireList();
    },
    getClassList() {
      this.$http
        .post("/Goods_Category/lst", {
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.classList = res.data.data;
        });
    },
    // 关闭本页面
    onClose() {
      this.$store.commit("chooseModule/onChoose", {
        chooseModule: "openAc1Module",
      });
      if (this.$store.state.chooseModule.row) {
        this.$store.commit("chooseModule/deleteRow");
      }
    },
    //添加商品
    productAdd() {
      this.dialogVisible = true;
      this.getProductList();
    },
    //获取商品列表
    getProductList() {
      this.$http
        .post(
          "product/lst",
          qs.stringify({
            currentPage: this.page,
            commercial_id:
              localStorage.getItem("commercial_id") ||
              sessionStorage.getItem("commercial_id"),
            cid: 0,
            currentLength: this.length,
          })
        )
        .then((res) => {
          this.tableData1 = res.data.data;
          this.dialogVisible = true;
          this.tableFinshNum = res.data.count;
        });
    },
    httpRequest(file) {
      this.getBase64(file.file).then((res) => {
        this.$http
          .post("uploads_images/up", qs.stringify({ file: res }))
          .then((ress) => {
            this.ruleForm.pic = ress.data.path;
          });
      });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    handleDelete(index) {
      this.ruleForm.inventory.splice(index, 1);
    },
    //下一页
    nextPage() {
      this.page = ++this.page;
      this.getProductList();
    },
    //上一页
    backPage() {
      this.page = --this.page;
      this.getProductList();
    },
    currentChange(e) {
      this.page = e;
      this.getProductList();
    },
    //商品状态改变
    tabelChenge(selection) {
      this.ruleForm.addList = selection;
    },
    //商品全选按钮发生改变
    tabelAllChenge(selection) {
      this.ruleForm.addList = selection;
    },
    //添加商品确定
    addConfirm() {
      this.dialogVisible = false;
      this.ruleForm.inventory.unshift(...this.ruleForm.addList);
      this.$refs.multipleTable.clearSelection();
      console.log(this.ruleForm);
    },
    // 商品档案搜索
    handleInquire() {
      this.$http
        .post(
          "/product/search",
          ({
            commercial_id: this.commercial_id,
            search: this.searchVal,
            product_label: this.value1,
            product_type: this.value2,
          })
        )
        .then((res) => {
          this.tableData1 = res.data.data;
          this.dialogVisible = true;
          this.tableFinshNum = res.data.count;
        });
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post(
              "group_activity/save",
              qs.stringify({
                activity_title: this.ruleForm.name,
                activity_rule: this.ruleForm.rule,
                thumbnail: this.ruleForm.pic,
                purchase_price: this.ruleForm.singlePrice,
                group_price: this.ruleForm.teamPrice,
                product_arr: this.ruleForm.inventory,
                target_number: this.ruleForm.peopleNum,
                limited_purchase: this.ruleForm.limitation,
                coupon: this.ruleForm.region,
                status: this.ruleForm.status,
                activity_inventory: this.ruleForm.StockNum,
                id: this.id,
                commercial_id: this.commercialId,
              })
            )
            .then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                this.$store.commit("chooseModule/onChoose", {
                  chooseModule: "openAc1Module",
                });
                if (this.$store.state.chooseModule.row) {
                  this.$store.commit("chooseModule/deleteRow");
                }
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleChange(file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList.slice(-3);
    },
    handleAvatarSuccess(file) {
      console.log(file);
      // this.ruleForm.pic = URL.createObjectURL(file.raw);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          this.ruleForm.addList = [];
          this.$refs.multipleTable.clearSelection();
          done();
        })
        .catch(() => { });
    },
    onSubmit() {
      console.log("submit!");
    },
    fileClick(i) {
      this.isLabel = i;
      console.log("文件选中" + i);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  watch: {
    labels1(val) {
      console.log(val);
      // this.labels_1 = val;
    },
    labels2(val) {
      console.log(val);
      // this.labels_2 = val;
    },
  },
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialogSelect {
  height: 32px;
}

.file_form {
  display: flex;
  justify-content: space-between;

  .file {
    padding: 20px;

    .file_level1 {
      display: flex;
      align-items: center;
      width: 120px;

      a {
        font-size: 14px;
        color: #333;
        font-family: "微软雅黑";
      }
    }

    .file_level2 {
      padding-left: 40px;
      margin-top: 3px;

      a {
        height: 20px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.tableBottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.product-img {
  max-height: 120px;
  width: 100%;
}

.label {
  background-color: #ffeabf;
}
</style>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 15px !important;
}

.el-input__inner {
  padding: 0 4px !important;
  color: #333 !important;
}

.el-form-item.is-error .el-input__inner {
  border: 1px solid #e5e5e5 !important;
}
</style>
